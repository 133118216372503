import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import token from './mixins/isValidToken.js'
Vue.config.productionTip = false
/*Vue.mixin({
  async kk() {
    axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.get('/testToken').catch((err) => {
            if(err.response.status){
              if(localStorage.token){
                localStorage.removeItem('token');
                window.location.reload()
              }
            }
        })
  }
})
*/
new Vue({
  router,
  store,
  vuetify,
  token,
  render: h => h(App)
}).$mount('#app')
