<template>
    <div>
        <v-snackbar
        :style="`margin-top:${calcMargin(currentSnackbar.i)}`"
        :key="currentSnackbar.i"
        :value="currentSnackbar.visible"
        auto-width
        :color="currentSnackbar.color"
        :multi-line="currentSnackbar.mode === 'multi-line'"
        :timeout="currentSnackbar.timeout"
        :top="true"
        :left="true"
        rounded
        transition="slide-x-transition"
      >
        <v-layout align-center pr-4>
          <v-icon class="pr-3" dark large>{{ currentSnackbar.icon }}</v-icon>
          <v-layout column>
            <div>
              <strong>{{ currentSnackbar.title }}</strong>
            </div>
            <div>{{ currentSnackbar.text }}</div>
          </v-layout>
          <v-btn v-if="currentSnackbar.timeout === -1 && snackbars.length === 1" icon @click="visible()" class="mr-0">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-badge
            :color="currentSnackbar.color"
            :content="snackbars.length-1"
            overlap
            v-if="snackbars.length > 1"
          >
          <v-btn v-if="snackbars.length > 1" icon @click="visible()">
            <v-icon>mdi-message-arrow-right</v-icon>
          </v-btn>
          </v-badge>
        </v-layout>
      </v-snackbar>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    computed: {
        ...mapState("Snackbars", ["currentSnackbar","snackbars"]),
    },
    methods: {
        calcMargin(i) {
          return (i * 90)+ "px";
        },
        ...mapActions("Snackbars", ["visible"]),
    },
};
</script>

<style>
</style>