<template>
  <div>
    <v-fab-transition>
      <v-btn
        fab
        dark
        small
        fixed
        absolute
        left
        v-show="fab"
        v-scroll="onScroll"
        bottom
        color="warning"
        @click="toTop"
        class="ml-1 mb-10"
        v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fab: false,
    };
  },
  components: {},
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo("#app", {
        duration: 600,
        offset: 500,
        easing: "easeInOutQuad",
      });
    },
  },
};
</script>