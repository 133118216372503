<template>
  <v-overlay :value="isVisible">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
</template>

<script>
import {mapState} from 'vuex'
export default {
    computed:{
        
    ...mapState("Loader", ['isVisible']),
    }
}
</script>

<style>

</style>