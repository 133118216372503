<template>
  <div id="app">
    <v-app id="instead">
      <v-main>
        <router-view></router-view>
      </v-main>
      <Snackbar/>
      <Loader/>
      <GoToTopFAB/>
    </v-app>
  </div>
</template>

<script>
import Snackbar from './components/Dialogs/Notifications/snackbar'
import Loader from './components/Dialogs/Notifications/loader'
import GoToTopFAB from './components/StandardizedComponents/goToTop' 

export default {
  name:'App',
  components: {
    Snackbar,
    Loader,
    GoToTopFAB
  },
};
</script>